.update-btn {
  border: 1px solid #34c38f;
  background-color: #34c38f;
  color: white;
  padding: 5px !important;
}
.update-btn:hover {
  background-color: #195e45;
}
.spinner {
  animation: spin 2s linear infinite;
}
.mar-gn {
  margin-left: 1rem !important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  // 50% {
  //   transform: rotate(270deg);
  // }
  100% {
    transform: rotate(360deg);
  }
}

.apexcharts-toolbar {
  display: none;
}
.premium-ui {
  .breadcrumb {
    box-shadow: rgba(100, 100, 111, 0) 0px 7px 29px 0px;
    .breadcrumb-item {
      height: 35px;
      min-width: 100px;
      width: fit-content;
      padding: 10px 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -15px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      &:nth-child(1) {
        border-radius: 50px;
      }
      cursor: pointer;
    }
    .breadcrumb-item + .breadcrumb-item::before {
      display: none;
    }
  }
}

.winner-div {
  position: absolute;
  .name {
    position: relative;
    top: 50%;
    right: 50%;
  }
}

.img-luck-div {
  padding: 10px;
}
.img-luck {
  width: 314px;
  height: 200px;
  object-fit: cover;
}
.winner_btn {
  color: black !important;
  border: none;
  margin: 1rem;
}
.wheel {
  color: orangered;
  font-size: 24px;
}
.lucky-draw-card-body {
  padding: 15px !important;
}
.lucky-draw-card {
  min-height: 100px;
}
.product-img-text {
  margin-top: auto;
  margin-bottom: auto;
}
.product-img {
  margin-left: 1rem;
}
.supply-chain-details {
  margin-left: 1rem;
}
.approve_btn {
  margin-right: 5px;
}
.long-row {
  padding-left: 12px;
  padding-right: 12px;
}
.chain-col-1 {
  padding-left: 0px !important;
}
.chian-main-div {
  display: flex;
  justify-content: center;
}
.chain-img {
  border-radius: 50%;
  height: 62px;
  width: 62px;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chain-head {
  font-size: 16px;
  color: #495057;
  font-weight: 500;
}
.chain-desc {
  font-weight: 400;
  font-size: 13px;
  color: #74788d;
}
.chain-div-desc {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%; /* Ensure the container takes up the full height of its parent */
}
.chain-check-in {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  & .center-vertically {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure the container takes up the full height of its parent */
  }
  .check-h6 {
    font-weight: 400;
    font-size: 13px;
    color: #74788d;
  }
  .date-h6 {
    font-weight: 500;
    font-size: 16px;
    color: #000;
  }
}
.chain-col-2 {
  margin-top: auto;
  margin-bottom: auto;
}
.chain-details {
  // font-size: 15px;
  font-weight: 400;
  // margin-bottom: 15px;
  color: #495057;
}
.chain-user-name {
  font-size: 14px;
  color: #495057;
  font-weight: 500;
}
.chain-user-desc {
  color: #74788d;
  font-size: 13px;
  line-height: 20px;
  margin-right: 31rem;
}
.card-supply-chain {
  // min-height: 250px;
}
.chain-promoter-details {
  color: #495057;
  font-weight: 600;
  font-size: 15px;
  // margin-bottom: 5px;
  margin: 0px !important;
}
.chain-personal-info {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
}
.li-content {
  color: #74788d;
  font-size: 13px;
  font-weight: 400;
  // padding-bottom: 4px;
  height: 22px;
}
.custom-list {
  list-style-type: none;
  padding-left: 0;
}
.custom-list li::before {
  content: '\2022'; /* Bullet character (•) */
  color: #74788d; /* Color of the bullet */
  font-size: 2.2rem;
  margin-right: 5%;
  display: inline-block; /* Display the bullet on the same line */
  vertical-align: middle; /* Vertically align the bullet with text */
}
.chain-personal-date {
  color: #74788d;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 25px;
}

.transaction-option-sidebar {
  margin-top: 10px;
  padding: 10px;
  .filter-header-1 {
    flex-direction: column;
    .btn-group {
      width: 100%;
      flex-direction: column;
      label {
        margin-bottom: 10px !important;
        border-radius: 16px !important;
      }
    }
    .sortWrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start !important;
      width: 100%;
      padding: 10px;
      p {
        margin-bottom: 10px !important;
      }
      .date_wrapper {
        .form-group {
          width: 260px !important;
        }
        flex-direction: column;
        .dateRange1 {
          margin-bottom: 10px;
          .form-control {
            margin: 0 !important;
          }
        }
      }
    }
  }
  .filter-header-2 {
    padding: 10px;
    .filter-subheader-2 {
      margin-top: 0 !important;
      flex-direction: column;
      form {
        width: 100%;
        margin-bottom: 10px;
      }
      .form-group {
        width: 100% !important;
        margin-right: 0 !important;
      }
      .w-25 {
        margin-bottom: 10px;
      }
    }
  }
}
.navbar-brand-box {
  width: 175px !important;
}
.leftspace {
  margin-left: 1rem;
}
.chain-transaction {
  font-weight: 400;
  font: size 15px;
  color: #495057;
}
.otp_input {
  height: 100%;
  width: 100% !important;
  border: #adb5bd solid;
  border-radius: 5px;
  color: black !important;
  @media screen and (max-width: 300px) {
    border: none;
  }
}
.otp_container div {
  width: 40px;
  height: 40px;
  margin-right: 13px;
}
.otp_focus {
  border: rgb(0, 0, 0) solid !important;
  @media screen and (max-width: 320px) {
    border: none !important;
  }
}

.ckeck-box-login {
  display: flex;
  flex-direction: row;
}
.modaledit {
  width: 700px !important;
}
.custom-textarea {
  height: auto;
  min-height: 100px;
  resize: vertical;
}
.comentytle {
  min-width: 150px;
}
.wt {
  margin-top: 12px;
}
.order-option-sidebar {
  padding: 20px 10px;
  .headerOptionSubWrap {
    flex-direction: column;
    align-items: flex-start !important;
    .search-box {
      width: 255px;
    }
    .sortWrapper {
      flex-direction: column;
      align-items: flex-start !important;
      p {
        margin-bottom: 10px !important;
      }
      .date_wrapper {
        flex-direction: column;
        .form-group {
          width: 255px !important;
          margin-bottom: 10px;
          input {
            margin: 0 !important;
          }
        }
      }
    }
    .statusBtn,
    .newOrderBtn,
    .shippingBtn {
      margin-bottom: 10px;
    }
  }
}
.order-option-table {
}

.react-drawer-drawer {
  box-shadow: none !important;
}
.osperb-editor {
  .jodit-status-bar a.jodit-status-bar-link {
    display: none !important;
  }
  .jodit-status-bar__item.jodit-status-bar__item-right {
    &::before {
      content: 'Powered by osperb';
      position: absolute;
      bottom: 21px;
      right: 25px;
    }
  }
}
.product-card-2 {
  width: 100%;
  position: relative;
  box-shadow: 0 2px 7px #dfdfdf;
  margin: 20px auto;
  background: #fafafa;
  display: flex;
}

.product-tumb {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
  border-radius: 10px;
  background: #f0f0f0;
}

.product-tumb img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.product-details {
  padding: 10px 30px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.product-details h4 a {
  font-weight: 500;
  display: block;
  margin-bottom: 18px;
  text-transform: uppercase;
  color: #363636;
  text-decoration: none;
  transition: 0.3s;
}

.product-details h4 a:hover {
  color: #34c38f;
}

.product-details p {
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 10px;
  color: #999;
}

.product-bottom-details {
  overflow: hidden;
  // border-top: 1px solid #eee;
}

.product-bottom-details div {
  float: left;
  width: 50%;
}

.product-price {
  font-size: 18px;
  color: #fbb72c;
  font-weight: 600;
}

///asas

.sms-modal .card-body {
  height: 300px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hover-zoom {
  transition: all 0.6s ease-in-out;
  &:hover {
    transform: scale(1.2);
    color: #2e9d5e !important;
  }
}
.hover-zoom2 {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.04);
    color: #2e9d5e !important;
  }
}
.ruler {
  div {
    canvas {
      height: 45px !important;
    }
    div {
      top: -10px !important;
    }
  }
}

.btn_close_hover {
  transition: all 0.5s ease-in-out;
  &:hover {
    // background-color: rgb(203, 64, 64) !important;
    // border: 1px solid rgb(203, 64, 64) !important;
    // color: #fff !important;
    background-color: #ffc3c3 !important;
    border: 1px solid #ffc3c3 !important;
  }
}
.btn_crop_hover {
  transition: all 0.5s ease-in-out;
  &:hover {
    background-color: #d5f2e1 !important;
  }
}
.custom-file .dropzone {
  height: 150px;
  width: 160px;
  min-height: 120px;
  margin-right: 5px;
}
.table-nowrap {
  .text-break-spaces {
    white-space: break-spaces;
  }
  // th, td {
  //   white-space: nowrap;
  // }
}
.markup {
  p {
    margin: 0;
  }
}

.custom_swipe .swiper-slide {
  display: none !important;
}

.vertical-middle {
  vertical-align: middle !important;
}
.pdfTable .react-bootstrap-table table {
  border-bottom: #fff !important;
}
.custom_up_btn {
  height: 35px;
  // margin: 0 12px;
  @media screen and (max-width: 1092) {
    margin: 0 !important;
  }
}
.myOrderTable tbody tr {
  line-height: 0 !important;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  display: none !important;
}
.custom_time_input {
  background: white;
  border-radius: 6px;
  border: 1px solid #ced4da;
}
.custom_time_input i {
  color: #2e9d5e !important;
  margin-left: 10px;
}
.custom_time_input .form-group input {
  border: none !important;
}
.revenue-avatar {
  height: 100px !important;
  width: 100px !important;
}
.date_wrapper .form-group {
  width: 160px !important;
}
.date_close {
  position: absolute;
  top: 3px;
  right: 25px;
  font-size: 15px !important;
  cursor: pointer;
  border: none !important;
  box-shadow: none !important;
}

.text_md_black {
  color: #494d51 !important;
}
// .text_deals_green {
//   color: #2e9d5e !important;
// }
.text_deals_yellow {
  color: #f5d646 !important;
}
.bg_deals_green {
  color: #2e9d5e !important;
}
.bg-whiteSmook {
  background-color: #ffffff92 !important;
}
.bg-gray {
  background-color: #f1f1f1 !important;
}
.bg-lightGray {
  background-color: #f6f6f696 !important;
}
.bg-yellow {
  // background-color: #f5d646 !important;
  background-color: #fbf3c8 !important;
}
.bg-lightYellow {
  background-color: #fcf8e261 !important;
}
.bg-green {
  // background-color: #2e9d5e !important;
  background-color: #d5f2e1 !important;
}
.bg-green-sea {
  background-color: #e0ecb4 !important;
}
.bg-green-peach {
  background-color: #d4f4ec !important;
}
.bg-green-dust {
  background-color: #fea889 !important;
}
.bg-lightGreen {
  // background-color: #d2f1dfcf !important;
  background-color: #f7fffacf !important;
}
.editor_input input {
  width: 80px !important;
}
.stock_input {
  width: 220px !important;
}
// .stock_input div {
//   // max-width: 220px !important;
// }
.stock_input .form-group {
  flex: 1;
  max-width: 70px;
}
.new_custom_store_select .select-selection__control {
  border: none !important;
  box-shadow: none !important;
}
.stock_input .form-group input {
  width: 100% !important;
  max-width: 60px;
}
.custom_store {
  position: absolute;
  top: 90px;
  z-index: 99;
  width: 100%;
  // max-width: 100vw;
  @media screen and (max-width: 768px) {
    position: initial;
  }
}

.custom_store_child {
  min-width: 200px;
  max-width: 300px;
  margin: auto;
}
.unit_select {
  background: white;
  border: 1px solid #ced4da;
  border-radius: 6px;
  padding: 0 !important;
}
.custom_store .select_store_wrapp {
  background: white;
  border: 1px solid #ced4da;
  border-radius: 6px;
}
.custom_store .form-group,
.custom_store .form-group select .unit_select .form-group,
.unit_select .form-group select {
  border: none !important;
}
.store_icon {
  font-size: 20px;
  color: #2e9d5e;
}
.select_cu_store {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hover-gray:hover {
  background-color: #ededed;
}
.timePickerWrapper div input {
  width: 60px !important;
  // border-color: #cfcfd4;
  border-radius: 8px;
  padding: 4px 10px;
  border: 1px solid #cfcfd4;
}
.pikDateAndTime .react-datetime-picker__wrapper {
  border-radius: 10px;
  padding: 3px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-color: #cfcfd4;
}

.cursor-pointer {
  cursor: pointer !important;
}
.parent_dateRange .rdrDefinedRangesWrapper {
  display: none !important;
}
.parent_dateRange {
  position: relative;
}
.parent_dateRange .rdrDateRangePickerWrapper,
.parent_dateRange .rdrCalendarWrapper {
  width: 100% !important;
}
.parent_dateRange .rdrDateDisplayWrapper {
  background-color: transparent !important;
}
.parent_dateRange .rdrDateDisplay {
  // color: #cccccc !important;
  margin: 6px 0;
}
.parent_dateRange .rdrMonthAndYearWrapper {
  padding-top: 0 !important;
  height: 45px !important;
}
.parent_dateRange .rdrMonthAndYearWrapper,
.parent_dateRange .rdrMonths {
  display: none !important;
  position: absolute !important;
  background-color: #ffffff !important;
  z-index: 99;
  top: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 92%;
}
.parent_dateRange .rdrMonths {
  top: 100px;
}
.parent_dateRange:hover .rdrMonthAndYearWrapper {
  display: flex !important;
}
.parent_dateRange:hover .rdrMonths {
  display: flex !important;
}
.parent_dateRange:hover .card-body {
  min-height: 450px !important;
}
// .dailyProductParent .parent_dateRange .rdrMonths {
//   top: 140px !important;
// }
// .dailyProductParent .parent_dateRange .rdrMonthAndYearWrapper,
// .parent_dateRange .rdrMonths {
//   top: 90px !important;
// }

.custom_margin_top {
  margin-top: -20px;
  @media screen and (max-width: 1200px) {
    margin-top: 0;
  }
  @media screen and (max-width: 992px) {
    margin-top: -20px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
}
// .custom_store_select {
//   @media screen and (max-width: 768px) {
//     margin-top: 20px;
//   }
// }

.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}
.text-skyBule {
  color: #1e81b0 !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}
#area_table .areaBadge {
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
}
.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.custom-dashed {
  border-right: dashed !important;
}

@media screen and (max-width: '768px') {
  .custom-dashed {
    border: none !important;
  }
}
.awdbtn {
  background: white !important;
  border: 1px solid #34c38f !important;
  color: #34c38f !important;
  border-radius: 5px;
}

.awbinput .form-group {
  width: 100%;
  margin-right: 15px;
}

.steps-new {
  background-color: #f2f2f2;
  padding: 5px !important;
  border-radius: 15px;
  .nav-item,
  .nav-link {
    border-radius: 15px;
    background-color: #f2f2f2 !important;
    padding: 10px;
    color: #8f8f8f !important;
  }
  .isActive.nav-link {
    color: #000 !important;
  }
  .current.nav-item {
    background-color: #ffffff !important;
    .active.nav-link {
      color: #000 !important;
      background-color: #ffffff !important;
    }
  }
}
.select-user {
  border: 1px solid #f2f2f2;
  border-radius: 15px;
  padding: 1.3rem;
  .css-yk16xz-control {
    border-radius: 10px;
  }
  .user-card {
    background: #c0eee4;
    padding: 1rem;
    border-radius: 10px;
  }
}
.navigation-btn {
  .previous {
    padding: 8px 15px;
    border-radius: 5px;
    color: #000;
    background-color: transparent;
    border: 1px solid #cfcfd4;
    cursor: pointer;
    &:hover {
      background-color: #ced4da;
      transition: all ease-in-out 0.3s;
    }
    &.disabled {
      color: #66696c;
      background-color: #cfcfd4;
      cursor: not-allowed;
    }
  }
  .next {
    padding: 8px 30px;
    border-radius: 5px;
    color: #fff;
    background-color: #34c38f;
    border: 1px solid #34c38f;
    cursor: pointer;
    &:hover {
      background-color: #2e9d5e;
      transition: all ease-in-out 0.3s;
    }
    &.disabled {
      color: #66696c;
      background-color: #cfcfd4;
      cursor: not-allowed;
      border: 1px solid #cfcfd4;
    }
  }
}
.user-address {
  .address-list {
    cursor: pointer;
    width: 240px;
    min-height: 200px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: auto;
    &.isActive {
      background-color: #eafbf5;
      border: 1px solid #34c38f;
    }
    &.dashed {
      border: 1px dashed #ced4da;
      background-color: #f7f7f7;
    }
    .corner {
      position: absolute;
      background-color: #1bd391;
      width: 50px;
      height: 50px;
      top: -25px;
      right: -25px;
      transform: rotate(45deg);
      i {
        position: absolute;
        color: #fff;
        bottom: 0;
        font-size: 20px;
        transform: rotate(-45deg);
        left: 14px;
      }
    }
    .remove {
      cursor: pointer;
      &:hover {
        svg {
          color: #f44336;
        }
      }
    }
    .edit {
      cursor: pointer;
      &:hover {
        svg {
          color: #34c38f;
        }
      }
    }
  }
}
.tickbtn {
  position: relative;
  overflow: hidden;
  .corner {
    position: absolute;
    background-color: #1bd391;
    width: 30px;
    height: 30px;
    top: -15px;
    right: -15px;
    transform: rotate(45deg);
    i {
      position: absolute;
      color: #fff;
      bottom: -1px;
      font-size: 15px;
      transform: rotate(-45deg);
      left: 7px;
    }
  }
}

.apply-coupon {
  display: flex;
  .form-group {
    flex: 1;
  }
  input {
    height: 40px;
    border-radius: 10px 0 0 10px;
    background-position: 20px center;
    background-repeat: no-repeat;
    padding: 20px;
    width: 260px;
  }

  button {
    min-width: 150px;
    height: 51px;
    border-radius: 0 10px 10px 0;
    background-color: #253d4e;

    &:hover {
      background-color: #22b3be;
    }
  }
}
.md\:pt-20 {
  @media screen and (max-width: 1200px) {
    padding-top: 20px !important;
    margin-top: 40px !important;
  }
}

.guest_counter {
  padding: 3px;
  background: #fff;
  box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
  border-radius: 10px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.guest_counter_icon {
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.guest_counter_icon.plus {
  // background: #e1f8e2;
  background: #f6f6f6;

  color: #165d19;
}
.guest_count {
  padding: 0 10px;
}
.guest_counter_icon.minus {
  background: #f6f6f6;
  // background: #fee6e8;
  color: #900c09;
}

.orderItemTable thead th:nth-child(5) {
  text-align: center;
}
.my-custom-success-soft {
  background-color: rgb(52 195 143 / 37%) !important;
  border-color: transparent !important;
  color: #165d19 !important;
  transition: all 0.5s ease;
}

.hvr-grow-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
}
.hvr-grow-shadow:hover,
.hvr-grow-shadow:focus,
.hvr-grow-shadow:active {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.051);
}

/* Box Shadow Outset */
.hvr-box-shadow-outset {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
}
.hvr-box-shadow-outset:hover,
.hvr-box-shadow-outset:focus,
.hvr-box-shadow-outset:active {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
}

/* Box Shadow Inset */
.hvr-box-shadow-inset {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.6), 0 0 1px rgba(0, 0, 0, 0);
  /* Hack to improve aliasing on mobile/tablet devices */
}
.hvr-box-shadow-inset:hover,
.hvr-box-shadow-inset:focus,
.hvr-box-shadow-inset:active {
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.6), 0 0 1px rgba(0, 0, 0, 0);
  /* Hack to improve aliasing on mobile/tablet devices */
}
.product-copy {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.special_buttons {
  // background: #c5f6dc;
  width: 120px;
  height: 80px;
  border-radius: 15px;
  color: #000 !important;
  &.no-eff {
    &:hover {
      background-color: #f0f2f6 !important;
    }
  }
  &.btn-success {
    &:hover {
      background-color: #2a9c72 !important;
    }
  }
}
.border-none {
  border: none !important;
}
.defaultSectionCard {
  h5 {
    color: #000;
  }
  &:hover {
    h5 {
      color: #22b3be;
    }
  }
}

.custom-padding {
  th,
  td {
    padding: 2px !important;
  }
  &.md {
    th,
    td {
      padding: 8px !important;
    }
  }
}
